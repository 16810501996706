import React from 'react';
import {Route, Switch, useRouteMatch} from 'react-router';
import Header from './Header';
import Information from './Information';
import Timeline from './Timeline';
import Mail from './Mail';
import Tel from './Tel';
import Lead from './Lead';
import Detail from './Detail';
import SideTab from './SideTab';
import {useCurrentClientQuery, usePreleadQuery} from 'api';
import CustomizeItem from './CustomizeItem';
import {PreleadContext} from './usePreleadContext';
import Task from './Task';
import Approach from './Approach';
import ContentTabs from './ContentTabs';
import {useMiitel} from './useMiitel';
import {createGlobalStyle} from 'styled-components';

interface Props {
  onClose: () => void;
  preleadId: string;
}

export default ({onClose, preleadId}: Props) => {
  const {path} = useRouteMatch();

  const {data: {prelead} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });

  const {data: {currentClient: {miitelSetting = {}} = {}} = {}} =
    useCurrentClientQuery({
      fetchPolicy: 'cache-and-network',
    });

  useMiitel(prelead?.uuid, miitelSetting);

  if (!prelead) return null;

  return (
    <PreleadContext.Provider value={{preleadId}}>
      <div className="flex h-full absolute z-[3] top-0 right-0">
        <Information />
        <SideTab />
        <div className="w-[600px] h-full flex flex-col bg-white border-l border-l-c-border">
          <div className="sticky z-10">
            <Header onClose={onClose} />
            <CustomizeItem />
            <ContentTabs />
          </div>
          <div className="overflow-scroll px-9 py-4">
            <Switch>
              <Route path={`${path}/timeline`} component={Timeline} />
              <Route path={`${path}/mail`} component={Mail} />
              <Route path={`${path}/tel`} component={Tel} />
              <Route path={`${path}/leads`} component={Lead} />
              <Route path={`${path}/task`} component={Task} />
              <Route path={`${path}/approach`} component={Approach} />
              <Route path={`${path}`} component={Detail} />
            </Switch>
          </div>
        </div>
        <GlobalStyle />
      </div>
    </PreleadContext.Provider>
  );
};

const GlobalStyle = createGlobalStyle`
  #miitelPhoneIFrameButton {
    height: 40px !important;
  }
`;
