import React from 'react';
import {Menu} from '@headlessui/react';
import {useAccountPoolQuery} from 'api';
import {useParams} from 'react-router-dom';
import {Salesforce} from 'components/Ui/Icon';

const SalesforceAccountUser = () => {
  const {accountId} = useParams<{
    accountId: string;
  }>();
  const {data: {accountPool = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  if (!accountPool.salesforceAccount) return null;
  const salesforceUser = accountPool?.salesforceAccount?.salesforceOwner;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-c-light">Salesforce所有者</div>
          <div className="flex w-max items-center gap-2 [&>svg]:w-4">
            <Salesforce />
            <span className="flex px-2 h-5 items-center gap-1 rounded-sm border-none bg-[#DCEEFF] text-xs font-bold text-[#005388]">
              所有者
            </span>
            {salesforceUser && (
              <span className={`py-1 rounded-full leading-none tracking-tight`}>
                {[salesforceUser?.lastName, salesforceUser?.firstName]
                  .filter(Boolean)
                  .join(' ')}
              </span>
            )}
          </div>
        </div>
      </div>
    </Menu>
  );
};

export default SalesforceAccountUser;
