import React from 'react';
import {KeyPerson} from 'api';
import {Row} from 'react-table';
import FacebookIcon from 'components/Ui/Icon/FacebookIcon';
import XcomIcon from 'components/Ui/Icon/XcomIcon';
import WantedIcon from 'components/Ui/Icon/WantedIcon';
import LinkedInIcon from 'components/Ui/Icon/LinkedInIcon';
import EightIcon from 'components/Ui/Icon/EightIcon';

interface Props {
  row: Row<KeyPerson>;
}

export default ({row}: Props) => {
  const keyPerson = row.original;
  return (
    <div className="flex items-center gap-2">
      {keyPerson?.facebookUrl && (
        <a
          href={keyPerson.facebookUrl}
          target="_href"
          className="cursor-pointer">
          <FacebookIcon />
        </a>
      )}
      {keyPerson?.linkedinUrl && (
        <a
          href={keyPerson.linkedinUrl}
          target="_href"
          className="cursor-pointer">
          <LinkedInIcon />
        </a>
      )}
      {keyPerson?.xUrl && (
        <a href={keyPerson.xUrl} target="_href" className="cursor-pointer">
          <XcomIcon />
        </a>
      )}
      {keyPerson?.wantedlyUrl && (
        <a
          href={keyPerson.wantedlyUrl}
          target="_href"
          className="cursor-pointer">
          <WantedIcon />
        </a>
      )}
      {keyPerson?.eightUrl && (
        <a href={keyPerson.eightUrl} target="_href" className="cursor-pointer">
          <EightIcon />
        </a>
      )}
    </div>
  );
};
