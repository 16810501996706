import React, {useCallback, useState} from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import {useHistory, useParams} from 'react-router';
import {BounceMail, Cross, OptoutMail, Search} from 'components/Ui/Icon';
import {Select, Input} from 'components/antd';
import {
  usePreleadProjectQuery,
  useProjectWithClientQuery,
  useProjectQuery,
  useUpdatePreleadMutation,
  useUpdateStatusActivityMutation,
  useCurrentUserQuery,
} from 'api';
import preleadCategoryColor from 'helpers/preleadCategoryColor';
import useProject from 'hooks/useProject';
import BlockRegisterModal from '../BlockRegisterModal';
import useProjectPath from 'hooks/useProjectPath';
import {useMiitel} from 'hooks/useMiitel';
import Menu from './Menu';
import Tel from './Tel';
import Copy from './Copy';
import AccountStatus from './AccountStatus';

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery();
  const history = useHistory();
  const {createPath} = useProjectPath();
  const {projectId, preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();

  const {data: {project: {client: {miitelSetting = {}} = {}} = {}} = {}} =
    useProjectWithClientQuery({
      variables: {uuid: projectId},
    });

  const {isOwnProject} = useProject(projectId);

  const [isBlockRegisterModalVisible, setIsBlockRegisterModalVisible] =
    useState(false);

  const showBlockRegisterModal = () => {
    setIsBlockRegisterModalVisible(true);
  };

  const closeDetail = () =>
    history.push(
      createPath(`projects/${projectId}/preleads${location.search}`),
    );

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      closeDetail();
    }
  }, []);

  React.useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, []);

  const {
    data: {preleadProject = {}, preleadProject: {prelead = {}} = {}} = {},
  } = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
  });

  const {data: {project: {preleadStatuses = []} = {}} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  const [selectedStatus, setSelectedStatus] = useState(preleadProject.status);

  const [updatePrelead] = useUpdatePreleadMutation();
  const updateName = React.useCallback(
    (e) => {
      if (e.target.value) {
        updatePrelead({
          variables: {uuid: prelead?.uuid, attributes: {name: e.target.value}},
        });
      } else {
        alert('プレリード名は必須です。');
        setPreleadName(prelead.name);
      }
    },
    [prelead],
  );

  const [updateStatusActivity] = useUpdateStatusActivityMutation({
    onCompleted: () => {
      if (isOwnProject && selectedStatus === 'block') {
        showBlockRegisterModal();
      }
    },
  });
  const updateStatus = React.useCallback(
    (value: string) => {
      setSelectedStatus(value);
      updateStatusActivity({
        variables: {uuid: preleadProjectId, clientPreleadStatusId: value},
      });
    },
    [preleadProjectId],
  );

  const [preleadName, setPreleadName] = useState(null);

  React.useEffect(() => {
    setPreleadName(prelead?.name);
  }, [prelead]);

  const handleChange = (e: any) => {
    setPreleadName(e.target.value);
  };

  useMiitel(preleadProject.uuid, miitelSetting);

  if (!prelead) return null;

  return (
    <Container>
      <MainSection>
        <div>
          {prelead.company?.status === 'closed' && (
            <ClosedWrapper>
              <div>廃業</div>
            </ClosedWrapper>
          )}
          {currentUser.role === 'admin' && <AccountStatus />}
          <StatusSelect
            value={preleadProject.clientPreleadStatusId}
            onSelect={updateStatus}
            dropdownMatchSelectWidth={false}
            dropdownClassName="prelead-status-dropdown"
            optionLabelProp="label">
            <StatusSelect.Option
              value={null}
              key={'pending'}
              label={
                <React.Fragment>
                  <StatusMark color={preleadCategoryColor('pending')} />
                </React.Fragment>
              }>
              <StatusMark color={preleadCategoryColor('pending')} />
              &nbsp; 未
            </StatusSelect.Option>
            {preleadStatuses.map((preleadStatus) => (
              <StatusSelect.Option
                value={preleadStatus.id}
                key={preleadStatus.id}
                label={
                  <React.Fragment>
                    <StatusMark
                      color={preleadCategoryColor(preleadStatus.category)}
                    />
                  </React.Fragment>
                }>
                <StatusMark
                  color={preleadCategoryColor(preleadStatus.category)}
                />
                &nbsp; {preleadStatus.name}
              </StatusSelect.Option>
            ))}
          </StatusSelect>
          <CompanyName
            key={preleadProjectId}
            value={preleadName}
            onChange={handleChange}
            onBlur={updateName}
            onPressEnter={updateName}
          />
          {prelead.mailAddress && <Menu prelead={prelead} />}
        </div>
        <div>
          {prelead.isUnsubscribed && (
            <div className="mr-2">
              <Unsubscribed>
                <OptoutMail />
                配信停止
              </Unsubscribed>
            </div>
          )}
          {prelead.isBounced && (
            <div className="">
              <Bounced>
                <BounceMail />
                バウンス
              </Bounced>
            </div>
          )}
        </div>
        <div className="max-h-[206px] overflow-y-scroll !block">
          <DetailSection>
            <DetailTable>
              <tbody>
                <Tel />
                {prelead.webSite && (
                  <tr>
                    <th>URL</th>
                    <td>
                      <a href={prelead.webSite} target="_blank">
                        {prelead.webSite}
                      </a>
                      <Copy copyText={prelead.webSite} />
                    </td>
                  </tr>
                )}
                {prelead.mailAddress && (
                  <tr>
                    <th>メール</th>
                    <td>
                      {prelead.mailAddress}
                      <Copy copyText={prelead.mailAddress} />
                    </td>
                  </tr>
                )}
                {prelead.address && (
                  <tr>
                    <th>住所</th>
                    <td>{prelead.address}</td>
                  </tr>
                )}
              </tbody>
            </DetailTable>
          </DetailSection>
        </div>
        <SearchLinkWrapper>
          <Search />
          <span>
            {prelead.corpNumber && (
              <SearchLink
                href={`https://www.google.com/search?q=${prelead.corpNumber}`}
                target="_blank">
                法人番号
              </SearchLink>
            )}
          </span>
          <span>
            <SearchLink
              href={`https://www.google.com/search?q=${prelead.name}`}
              target="_blank">
              会社名
            </SearchLink>
          </span>
          <span>
            {prelead.address && (
              <SearchLink
                href={`https://www.google.com/search?q=${prelead.address}`}
                target="_blank">
                住所
              </SearchLink>
            )}
          </span>
          <span>
            {prelead.representativePerson && (
              <SearchLink
                href={`https://www.google.com/search?q=${prelead.representativePerson}`}
                target="_blank">
                代表者
              </SearchLink>
            )}
          </span>
        </SearchLinkWrapper>
      </MainSection>

      <CloseButton onClick={closeDetail}>
        <Cross />
      </CloseButton>

      <BlockRegisterModal
        isModalVisible={isBlockRegisterModalVisible}
        setIsModalVisible={setIsBlockRegisterModalVisible}
      />

      <GlobalStyle />
    </Container>
  );
};

const GlobalStyle = createGlobalStyle`
  #miitelPhoneIFrameButton {
    height: 40px !important;
    right: 80px !important;
  }
  #miitelPhoneIFrameOuter {
    right: 80px !important;
  }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 18px 22px;
`;

const MainSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;

const ClosedWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ff0000;
  border: 1px solid currentColor;
  margin: 0 21px;
  width: 42px;
  height: 22px;
  font-weight: bold;
  font-size: 10px;
`;

const CompanyName = styled(Input)`
  width: 380px;
  font-size: 18px;
  line-height: 26px;
  border-color: transparent;

  &:hover {
    border-color: #e2e6ea;
  }
`;

const CloseButton = styled.span`
  position: absolute;
  top: 24px;
  right: 24px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 8px;

  svg {
    margin: 9px;
  }
`;

const StatusSelect = styled(Select)`
  &&& {
    min-width: 16px !important;
    margin-right: 8px;

    .ant-select-selector {
      padding: 0;
      border: none;
    }

    .ant-select-selection-item {
      padding: 0;
    }

    .ant-select-arrow {
      display: none;
    }
  }
`;

const StatusMark = styled.span<{color: string}>`
  display: inline-block;
  vertical-align: middle;
  height: 16px;
  width: 16px;
  border-radius: 4px;
  background-color: ${({color}) => color};
  ${({color}) => !color && 'border: 1px solid #bdc4cb'};
`;

const DetailTable = styled.table`
  th {
    height: 24px;
    text-align: left;
    font-size: 10px;
    line-height: 14px;
    letter-spacing: 0.15px;
    color: #899098;
    max-width: 160px;
  }

  tr {
    height: 24px;
  }

  td {
    height: 24px;
    display: flex;
    justify-content: left;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    a {
      max-width: 320px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .ant-btn {
    display: flex;
    border: none;
    margin: 0;
    padding: 0;
    align-items: center;
  }

  .copy {
    margin-left: 8px;
    cursor: pointer;
  }
`;

const Unsubscribed = styled.div`
  color: #eb5757;
  font-size: 12px;
  background: #ffeeed;
  border-radius: 2px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  gap: 3px;

  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #eb5757;
    }
  }
`;

const Bounced = styled.div`
  color: #899098;
  font-size: 12px;
  background: #e1e6eb;
  border-radius: 2px;
  padding: 2px 5px;
  display: flex;
  align-items: center;
  gap: 3px;
  svg {
    width: 16px;
    height: 16px;
    path {
      stroke: #899098;
    }
  }
`;

const SearchLinkWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;

  svg {
    width: 18px;
    height: 14px;
  }
  span {
    margin-left: 6px;
  }
`;

const SearchLink = styled.a`
  color: #899098;
  font-size: 10px;
  text-decoration: underline;
`;

const DetailSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 22px 12px;

  > div {
    width: 100%;
    display: flex;
    align-items: center;
  }
`;
