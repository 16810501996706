import React from 'react';
import styled from 'styled-components';
import {Route, Switch} from 'react-router';
import Header from './Header';
import Information from './Information';
import {TabComponent as Tabs} from './Tabs';
import {Timeline, Detail, Lead, Tel, Mail, Letter, Task} from './Item';
import CustomizeItem from './CustomizeItem';
import Footer from './Footer';
import SideTab from './SideTab';
import projectRoutePaths from 'helpers/projectRoutePaths';
import ProjectGroupCustomizeItem from './ProjectGroupCustomizeItem';
import {useCurrentClientQuery} from 'api';

export default () => {
  const {data: {currentClient = {}} = {}} = useCurrentClientQuery();

  return (
    <Container>
      <Information />
      <SideTab />
      <PreleadInfo>
        <Header />
        <ScrollWrapper>
          <CustomizeItem />
          {currentClient.domain === 'ja-la' && <ProjectGroupCustomizeItem />}
          <div className="sticky bg-white z-10 top-0">
            <Tabs />
          </div>
          <ContentWrapper>
            <Switch>
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId/timeline',
                )}
                component={Timeline}
              />
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId/mail',
                )}
                component={Mail}
              />
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId/tel',
                )}
                component={Tel}
              />
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId/letter',
                )}
                component={Letter}
              />
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId/leads',
                )}
                component={Lead}
              />
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId/task',
                )}
                component={Task}
              />
              <Route
                path={projectRoutePaths(
                  'projects/:projectId/preleads/:preleadProjectId',
                )}
                component={Detail}
              />
            </Switch>
          </ContentWrapper>
        </ScrollWrapper>
        <Footer />
      </PreleadInfo>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0;
  right: 0;
`;

const PreleadInfo = styled.div`
  width: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-left: 1px solid #e1e6eb;
  z-index: 10;
`;

const ContentWrapper = styled.div`
  padding: 0 35px;
`;

const ScrollWrapper = styled.div`
  overflow: scroll;
`;
