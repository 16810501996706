import React from 'react';

function Icon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 17.8245C2.99987 16.687 3.27563 15.5665 3.80361 14.559C4.33159 13.5515 5.09604 12.6871 6.03142 12.04C6.9668 11.3928 8.0452 10.9821 9.17413 10.8432C10.3031 10.7043 11.4488 10.8412 12.5132 11.2423"
        stroke="#0075E2"
        strokeWidth="1.75829"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0334 10.7914C12.4611 10.7914 14.4291 8.82341 14.4291 6.39572C14.4291 3.96803 12.4611 2 10.0334 2C7.60573 2 5.6377 3.96803 5.6377 6.39572C5.6377 8.82341 7.60573 10.7914 10.0334 10.7914Z"
        stroke="#0075E2"
        strokeWidth="1.75829"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.4946 18C13.4657 18 14.2529 17.2128 14.2529 16.2417C14.2529 15.2706 13.4657 14.4834 12.4946 14.4834C11.5235 14.4834 10.7363 15.2706 10.7363 16.2417C10.7363 17.2128 11.5235 18 12.4946 18Z"
        stroke="#0075E2"
        strokeWidth="1.75829"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7701 10.9673L13.814 14.9234"
        stroke="#0075E2"
        strokeWidth="1.75829"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8906 11.8457L17.7698 12.7248"
        stroke="#0075E2"
        strokeWidth="1.75829"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Icon;
