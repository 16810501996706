import React from 'react';

export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.25 7C12.25 5.60761 11.6969 4.27226 10.7123 3.28769C9.72774 2.30312 8.39239 1.75 7 1.75C5.53231 1.75552 4.12357 2.32821 3.06833 3.34833L1.75 4.66667"
        stroke="#4D4D4D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 1.75V4.66667H4.66667"
        stroke="#4D4D4D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 7C1.75 8.39239 2.30312 9.72774 3.28769 10.7123C4.27226 11.6969 5.60761 12.25 7 12.25C8.46769 12.2445 9.87643 11.6718 10.9317 10.6517L12.25 9.33333"
        stroke="#4D4D4D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 9.33337H12.2502V12.25"
        stroke="#4D4D4D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
