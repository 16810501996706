import React from 'react';
import {
  useUpdateClientAccountScoreSettingAccountClosingMonthBeforeMutation,
  useAccountScoreSettingQuery,
} from 'api';

const Setting = () => {
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const [update] =
    useUpdateClientAccountScoreSettingAccountClosingMonthBeforeMutation({});

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 items-start">
        <div>
          <h2 className="text-base m-0 font-bold">決算時期</h2>
          <p className="m-0 text-sm">
            重要度を高める決算時期を選択してください。
          </p>
        </div>
        <div className="flex items-center gap-2">
          <select
            className="form-select border-c-border rounded text-sm w-36"
            value={accountScoreSetting?.accountClosingMonthBefore ?? 0}
            onChange={(e) => {
              update({
                variables: {
                  accountClosingMonthBefore:
                    e.target.value !== '0' ? Number(e.target.value) : null,
                },
              });
            }}>
            <option value={0}></option>
            {[...Array(12)]
              .map((_, i) => i + 1)
              .map((option) => {
                return (
                  <option value={option} key={option}>
                    {option}
                  </option>
                );
              })}
          </select>
          <span>ヶ月前</span>
        </div>
      </div>
    </div>
  );
};

export default Setting;
