import React from 'react';
import {Select} from 'components/antd';
import useSearchParams from '../../useSearchParams';

const options = [{text: 'あり', value: 1}];

const HasKeyPersonFilter = () => {
  const {query, setQuery} = useSearchParams();

  return (
    <Select
      dropdownMatchSelectWidth={false}
      allowClear
      value={query.hasKeyPerson}
      onChange={(value) => setQuery({page: 1, hasKeyPerson: value})}>
      {options.map((option) => (
        <Select.Option
          value={option.value}
          key={option.text}
          label={option.text}>
          {option.text}
        </Select.Option>
      ))}
    </Select>
  );
};

export default HasKeyPersonFilter;
