import React from 'react';
import Modal from './Modal';
import {XMarkIcon} from '@heroicons/react/24/outline';
import {
  useAccountScoreSettingQuery,
  useUpdateClientAccountScoreSettingSubCategoriesMutation,
  useUpdateClientAccountScoreSettingMutation,
} from 'api';

export default () => {
  const [visible, setVisible] = React.useState(false);
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateSubCategories] =
    useUpdateClientAccountScoreSettingSubCategoriesMutation({});
  const [updateSetting] = useUpdateClientAccountScoreSettingMutation({});
  return (
    <>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div className="mb-4 cursor-pointer">
            <label className="flex items-center text-base cursor-pointer">
              <input
                type="checkbox"
                className="form-checkbox h-5 w-5 rounded border-c-border cursor-pointer"
                checked={accountScoreSetting?.includeSubCategory}
                onChange={(e) => {
                  updateSetting({
                    variables: {
                      attributes: {
                        includeSubCategory: e.target.checked,
                      },
                    },
                  });
                }}
              />
              <span className="ml-2">スコア評価の対象にする</span>
            </label>
          </div>
          <div>
            <h2 className="text-base m-0 font-bold">業種</h2>
            <p className="m-0 text-sm">
              重要度を高める業種を選択してください。
            </p>
          </div>

          <button
            className="bg-white border border-c-border rounded h-8 text-sm px-4 cursor-pointer"
            onClick={() => setVisible(true)}>
            業種を設定
          </button>
          <div className="flex items-center gap-2 flex-wrap">
            {accountScoreSetting?.subCategories?.map((category) => {
              return (
                <span
                  className="bg-c-bg h-6 rounded text-sm px-4 flex items-center gap-1"
                  key={category?.id}>
                  {category?.displayName}{' '}
                  <XMarkIcon
                    className="h-4 w-4 cursor-pointer"
                    onClick={() => {
                      updateSubCategories({
                        variables: {
                          subCategoryIds:
                            accountScoreSetting?.subCategoryIds?.filter(
                              (id) => id !== category.id,
                            ),
                        },
                      });
                    }}
                  />
                </span>
              );
            })}
          </div>
        </div>
      </div>

      <Modal visible={visible} onClose={() => setVisible(false)} />
    </>
  );
};
