import React from 'react';
import employeeRangeOptions from './employeeRangeOptions';
import {
  useUpdateClientAccountScoreSettingEmployeeRangeMutation,
  useAccountScoreSettingQuery,
} from 'api';

const Setting = () => {
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const [update] = useUpdateClientAccountScoreSettingEmployeeRangeMutation({});

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 items-start">
        <div>
          <h2 className="text-base m-0 font-bold">従業員数</h2>
          <p className="m-0 text-sm">
            選択した従業員数の重要度を高めてスコアを算出します。
          </p>
        </div>

        <div className="flex items-center gap-2">
          <select
            className="form-select border-c-border rounded text-sm w-36"
            value={accountScoreSetting?.employeeRange}
            onChange={(e) => {
              update({
                variables: {
                  employeeRange: e.target.value,
                },
              });
            }}>
            {employeeRangeOptions.map((option) => {
              return (
                <option value={option?.value} key={option?.value}>
                  {option?.label}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
};

export default Setting;
