import React from 'react';
import {useParams} from 'react-router-dom';
import {useKeyPersonsByPreleadQuery, usePreleadProjectQuery} from 'api';
import Name from './Name';
import Sns from './Sns';
import Department from './Department';
import Position from './Position';
import Media from './Media';

export default () => {
  const {preleadProjectId} = useParams<{preleadProjectId: string}>();
  if (!preleadProjectId) return null;

  const {data: {preleadProject = {}} = {}} = usePreleadProjectQuery({
    variables: {uuid: preleadProjectId},
    fetchPolicy: 'cache-and-network',
  });

  const {data: {keyPersonsByPrelead = []} = {}} = useKeyPersonsByPreleadQuery({
    variables: {
      uuid: preleadProject.prelead.uuid,
    },
    skip: !preleadProject,
  });

  return (
    <div>
      <h2 className="mb-4 font-bold text-[12px] text-c-base">キーマン情報</h2>
      {keyPersonsByPrelead.map((keyPerson) => (
        <div key={keyPerson.id} className="border-b border-c-border p-4">
          <div className="flex">
            <Name keyPerson={keyPerson} />
            <Sns keyPerson={keyPerson} />
          </div>
          <Department keyPerson={keyPerson} />
          <Position keyPerson={keyPerson} />
          <Media keyPerson={keyPerson} />
        </div>
      ))}
    </div>
  );
};
