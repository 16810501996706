import gql from 'graphql-tag';

export default gql`
  fragment accountPoolScoreFragment on AccountPoolScore {
    id
    totalScore
    subCategoriesScore
    scoreRank
    employeeRangeScore
    accountClosingMonthScore
    capitalFundRangeScore
  }
`;
