import React from 'react';
import styled from 'styled-components';
import {Upload} from 'components/antd';

interface Props {
  value: string;
  onSelect: (file: File, width: number, height: number) => void;
}

export default ({value, onSelect}: Props) => (
  <Container>
    {!value || value.length === 0 ? <Empty /> : <Img src={value} />}
    <Upload
      onChange={(info) => {
        const file = info.file.originFileObj;
        const img = document.createElement('img');
        const objectUrl = window.URL.createObjectURL(file);

        img.onload = () => {
          const width = img.naturalWidth;
          const height = img.naturalHeight;
          onSelect(file, Math.trunc(width), Math.trunc(height));
        };

        img.src = objectUrl;
      }}
      showUploadList={false}
      accept=".png, .jpeg, .jpg, .gif"
      customRequest={() => false}>
      アップロード
    </Upload>
  </Container>
);

const Container = styled.div`
  border: solid 1px #dae0e5;
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;

  .ant-upload {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
    visibility: hidden;
  }

  &:hover {
    .ant-upload {
      visibility: visible;
    }
  }
`;

const Empty = styled.div`
  width: 100%;
  height: 100px;
  background-image: linear-gradient(45deg, #ccc 25%, transparent 25%),
    linear-gradient(135deg, #ccc 25%, transparent 25%),
    linear-gradient(45deg, transparent 75%, #ccc 75%),
    linear-gradient(135deg, transparent 75%, #ccc 75%);
  background-size: 10px 10px;
  background-position: 0 0, 5px 0, 5px -5px, 0px 5px;
`;

const Img = styled.img`
  max-width: 100%;
`;
