import {KeyPerson} from 'api';
import React from 'react';
interface Props {
  keyPerson: KeyPerson;
}

export default ({keyPerson}: Props) => {
  return (
    <>
      <span className="mr-6 mb-2 font-bold text-c-base text-[16px]">
        {keyPerson.lastName} {keyPerson.firstName}
      </span>
    </>
  );
};
