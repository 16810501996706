import {useAccountPoolQuery, useAccountScoreSettingQuery} from 'api';
import React from 'react';
import {useParams} from 'react-router-dom';

const NotTarget = () => {
  return <span className="text-[#B3B3B3] font-bold text-xs mr-8">対象外</span>;
};

const Score = ({score}: {score: number}) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex gap-[1px] items-center">
        <span className="bg-score-green-foreground h-[6px] w-4 rounded-l-full" />
        <span
          className={`${score >= 2 ? 'bg-score-green-foreground' : 'bg-gray-200'} h-[6px] w-4`}
        />
        <span
          className={`${score == 3 ? 'bg-score-green-foreground' : 'bg-gray-200'} h-[6px] w-4 rounded-r-full`}
        />
      </div>
      <span className="font-bold text-xs w-4">{score}</span>
    </div>
  );
};

const ScoreItem = ({
  title,
  score,
  target = true,
}: {
  title: string;
  score: number;
  target?: boolean;
}) => {
  return (
    <div className="flex justify-between items-center">
      <h4 className="text-xs">{title}</h4>
      {target ? <Score score={score} /> : <NotTarget />}
    </div>
  );
};

export default () => {
  const {accountId} = useParams<{accountId: string}>();
  const {data: {accountPool: {score = null} = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="text-c-light">企業スコア</div>
      <div>
        <span className="font-bold text-lg text-score-green-foreground bg-score-green rounded-sm h-6 w-6 flex items-center justify-center">
          {score?.scoreRank}
        </span>
      </div>
      <div className="grid grid-cols-2 gap-12">
        <div>
          <ScoreItem
            title="業種"
            score={score?.subCategoriesScore}
            target={accountScoreSetting?.includeSubCategory}
          />
          <ScoreItem title="決算" score={score?.accountClosingMonthScore} />
          <ScoreItem title="従業員数" score={score?.employeeRangeScore} />
          <ScoreItem title="資本金" score={score?.capitalFundRangeScore} />
        </div>
        <div>
          <ScoreItem title="事業内容" score={1} />
          <ScoreItem title="エリア" score={3} />
          <ScoreItem title="求人・メディア" score={2} />
        </div>
      </div>
    </div>
  );
};
