import React from 'react';
import UpdateLogo from './UpdateLogo';
import {useUpdateAccountPoolScoresMutation} from 'api';

export default () => {
  const [update, {loading}] = useUpdateAccountPoolScoresMutation({});
  return (
    <div className="w-full h-9 flex items-center justify-end">
      <button
        className="h-full border border-[#E6E6E6] rounded bg-white px-3 py-1 cursor-pointer flex items-center justify-between gap-2 leading-none"
        disabled={loading}
        onClick={() => {
          update();
        }}>
        <UpdateLogo />
        <span>顧客スコアを更新</span>
      </button>
    </div>
  );
};
