import React from 'react';

export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_56750_17901)">
        <path
          d="M7.34167 1.50858C7.12293 1.28977 6.82623 1.16681 6.51684 1.16675H2.33317C2.02375 1.16675 1.72701 1.28966 1.50821 1.50846C1.28942 1.72725 1.1665 2.024 1.1665 2.33341V6.51708C1.16657 6.82648 1.28953 7.12317 1.50834 7.34191L6.58567 12.4192C6.8508 12.6827 7.2094 12.8306 7.58317 12.8306C7.95694 12.8306 8.31554 12.6827 8.58067 12.4192L12.419 8.58091C12.6825 8.31578 12.8303 7.95719 12.8303 7.58341C12.8303 7.20964 12.6825 6.85105 12.419 6.58591L7.34167 1.50858Z"
          stroke="#999999"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.37516 4.66659C4.53625 4.66659 4.66683 4.536 4.66683 4.37492C4.66683 4.21384 4.53625 4.08325 4.37516 4.08325C4.21408 4.08325 4.0835 4.21384 4.0835 4.37492C4.0835 4.536 4.21408 4.66659 4.37516 4.66659Z"
          fill="#999999"
          stroke="#999999"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_56750_17901">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
