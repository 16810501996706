import {KeyPerson} from 'api';
import React from 'react';
interface Props {
  keyPerson: KeyPerson;
}

export default ({keyPerson}: Props) => {
  if (!keyPerson.department) return null;

  return (
    <>
      <div className="text-xs text-c-light truncate leading-none mb-2">
        {keyPerson.department}
      </div>
    </>
  );
};
