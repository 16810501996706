import React from 'react';
import {PencilSquareIcon, CheckIcon} from '@heroicons/react/20/solid';
import {Menu, Transition} from '@headlessui/react';
import {
  useUsersByClientAllQuery,
  useUpdateAccountPoolUserMutation,
  useAccountPoolQuery,
  User,
} from 'api';
import {useParams} from 'react-router-dom';

const UserSelect = () => {
  const {accountId} = useParams<{
    accountId: string;
  }>();

  const {data: {accountPool = {}} = {}} = useAccountPoolQuery({
    variables: {uuid: accountId},
    skip: !accountId,
  });
  const [updateAccountPoolUser] = useUpdateAccountPoolUserMutation({});

  const {data: {usersByClientAll: {users = []} = {}} = {}} =
    useUsersByClientAllQuery({
      variables: {search: {active: true}},
    });
  const selectedUser: User = accountPool?.user;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div className="flex items-center justify-between">
        <div>
          <div className="text-c-light">担当者</div>
          <span className={`py-1 rounded-full leading-none tracking-tight`}>
            {[selectedUser?.lastName, selectedUser?.firstName]
              .filter(Boolean)
              .join(' ')}
          </span>
        </div>
        <Menu.Button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
          <PencilSquareIcon className="w-5 h-5 text-c-primary" />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute origin-top-right rounded bg-white shadow z-10 w-[calc(100%-3rem)] right-12">
          <Menu.Item>
            {({active}) => (
              <div
                onClick={() =>
                  updateAccountPoolUser({
                    variables: {
                      id: accountPool?.id,
                      userId: null,
                    },
                  })
                }
                className={`${
                  active ? 'bg-c-bg' : ''
                } w-full flex items-center gap-2 py-1 px-2 cursor-pointer`}>
                <CheckIcon
                  className={`w-4 h-4 ${selectedUser == null ? 'text-c-primary' : 'text-transparent'}`}
                />
                <div className="flex flex-col">
                  <h4 className="text-sm font-normal m-0">企業担当者なし</h4>
                </div>
              </div>
            )}
          </Menu.Item>
          {users.map((user) => (
            <Menu.Item key={user.id}>
              {({active}) => (
                <div
                  onClick={() =>
                    updateAccountPoolUser({
                      variables: {
                        id: accountPool?.id,
                        userId: user?.id,
                      },
                    })
                  }
                  className={`${
                    active ? 'bg-c-bg' : ''
                  } w-full flex items-center gap-2 py-1 px-2 cursor-pointer`}>
                  <CheckIcon
                    className={`w-4 h-4 ${user.id === selectedUser?.id ? 'text-c-primary' : 'text-transparent'}`}
                  />
                  <div className="flex flex-col">
                    <h4 className="text-sm font-normal m-0">
                      {[user?.lastName, user?.firstName]
                        .filter(Boolean)
                        .join(' ')}
                    </h4>
                  </div>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default UserSelect;
