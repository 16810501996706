import {
  useCurrentClientQuery,
  usePreleadProjectQuery,
  useUpdatePreleadClientAccountStatusMutation,
} from 'api';
import React from 'react';
import {Menu, Transition} from '@headlessui/react';
import {CheckIcon} from '@heroicons/react/20/solid';
import {useParams} from 'react-router-dom';

export default () => {
  const {preleadProjectId} = useParams<{
    projectId: string;
    preleadProjectId: string;
  }>();
  const {data: {preleadProject: {prelead = {}} = {}} = {}} =
    usePreleadProjectQuery({
      variables: {uuid: preleadProjectId},
    });

  const {data: {currentClient: {accountStatuses = []} = {}} = {}} =
    useCurrentClientQuery({});
  const [updatePreleadClientAccountStatus] =
    useUpdatePreleadClientAccountStatusMutation({});

  const selectedStatus = prelead.clientAccountStatus;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="cursor-pointer bg-transparent border-none outline-none flex items-center">
        {selectedStatus && (
          <span
            className={`max-w-[5rem] truncate px-3 py-1 rounded-full text-xs leading-none font-bold tracking-tight bg-[#DCEEFF] text-[#005388]`}>
            {selectedStatus?.name}
          </span>
        )}
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <Menu.Items className="absolute origin-top-right rounded bg-white shadow w-60 z-[100]">
          {accountStatuses.map((status) => (
            <Menu.Item key={status.id}>
              {({active}) => (
                <div
                  onClick={() =>
                    updatePreleadClientAccountStatus({
                      variables: {
                        id: prelead.id,
                        clientAccountStatusId: status.id,
                      },
                    })
                  }
                  className={`${
                    active ? 'bg-c-bg' : ''
                  } w-full flex items-center gap-2 py-1 px-2 cursor-pointer z-10`}>
                  <CheckIcon
                    className={`w-4 h-4 ${status.id === selectedStatus?.id ? 'text-c-primary' : 'text-transparent'}`}
                  />
                  <div className="flex flex-col">
                    <h4 className="text-sm font-normal m-0">{status.name}</h4>
                    <p className="m-0 p-0 text-xs text-c-light">
                      {status.description}
                    </p>
                  </div>
                </div>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
