import React from 'react';
import {
  useAccountScoreSettingQuery,
  useUpdateClientAccountScoreSettingCapitalFundRangesMutation,
} from 'api';

const Setting = () => {
  const {data: {accountScoreSetting = {}} = {}} = useAccountScoreSettingQuery(
    {},
  );
  const [update] = useUpdateClientAccountScoreSettingCapitalFundRangesMutation(
    {},
  );
  const selected = accountScoreSetting?.capitalFundRanges ?? [];

  const options = [
    {label: '5,000万円未満', value: 'under_fifty_million'},
    {
      label: '5,000万円~1億円未満',
      value: 'fifty_million_to_one_hundred_million',
    },
    {
      label: '1億円~5億円未満',
      value: 'one_hundred_million_to_five_hundred_million',
    },
    {label: '5億円~10億円未満', value: 'five_hundred_million_to_one_billion'},
    {label: '10億円~50億円未満', value: 'one_billion_to_fifty_billion'},
    {label: '50億円以上', value: 'over_fifty_billion'},
  ];

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col gap-2 items-start">
        <div>
          <h2 className="text-base m-0 font-bold">資本金</h2>
          <p className="m-0 text-sm">
            重要度を高める資本金を選択してください。
          </p>
        </div>
        <div className="items-center gap-2 grid grid-cols-2">
          {options.map((option, index) => {
            return (
              <label className="flex items-center text-base" key={index}>
                <input
                  type="checkbox"
                  className="form-checkbox h-5 w-5 rounded border-c-border"
                  checked={selected.includes(option.value)}
                  onChange={(e) => {
                    const arr = e.target.checked
                      ? [...selected, option.value]
                      : selected.filter((range) => range !== option.value);
                    update({
                      variables: {
                        capitalFundRanges: arr,
                      },
                    });
                  }}
                />
                <span className="ml-2">{option.label}</span>
              </label>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Setting;
