import gql from 'graphql-tag';

export default gql`
  fragment accountScoreSettingFragment on AccountScoreSetting {
    id
    subCategoryIds
    employeeRange
    accountClosingMonthBefore
    capitalFundRanges
    includeSubCategory
  }
`;
