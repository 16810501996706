import React from 'react';
import Prospects from './Prospects';
import Information from './Information';
import RightContent from './RightContent';
import Signal from './Signal';
import CustomizeItem from './CustomizeItem';
import {useCurrentUserQuery} from 'api';

export default () => {
  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});
  return (
    <div className="flex gap-6">
      <div className="flex-1 flex flex-col gap-4">
        <Prospects />
        {currentUser?.role === 'admin' && <CustomizeItem />}
        <Information />
        <Signal />
      </div>
      <RightContent />
    </div>
  );
};
