import React from 'react';
import {useClientPreleadCustomizeItemsQuery} from 'api';
import InputField from './InputField';

export default () => {
  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery({});

  const availableItems = () => {
    return clientPreleadCustomizeItems?.filter((item) => item?.isDisplay);
  };

  return (
    <div className="bg-white border border-c-border rounded">
      <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
        カスタム項目
      </h2>
      <div className="p-4 flex flex-col gap-5">
        {availableItems()?.map((item) => {
          return <InputField key={item.id} item={item} />;
        })}
      </div>
    </div>
  );
};
