import React from 'react';

export default () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.75 3.49992C1.75 2.85559 2.27233 2.33325 2.91667 2.33325H11.0833C11.7277 2.33325 12.25 2.85559 12.25 3.49992V11.6666C12.25 12.3109 11.7277 12.8333 11.0833 12.8333H2.91667C2.27233 12.8333 1.75 12.3109 1.75 11.6666V3.49992Z"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.75 5.83325H12.25"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3335 1.16675V3.50008"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.6665 1.16675V3.50008"
        stroke="#999999"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
