import gql from 'graphql-tag';

export default gql`
  fragment mailCampaignContractFragment on MailCampaignContract {
    id
    mailCampaignSendsLimit
    canSendNotOpenedCountsMore
    canSendBlastengine
  }
`;
