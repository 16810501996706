import React from 'react';
import Category from './Category';
import UpdateScoreButton from './UpdateScoreButton';
import Employee from './Employee';
import AccountClosingMonthBefore from './AccountClosingMonthBefore';
import CapitalFund from './CapitalFund';
import JobOfferMedia from './JobOfferMedia';

export default () => {
  return (
    <div className="flex flex-col gap-4">
      <UpdateScoreButton />
      <Category />
      <Employee />
      <CapitalFund />
      <AccountClosingMonthBefore />
      <JobOfferMedia />
    </div>
  );
};
