import gql from 'graphql-tag';

export default gql`
  fragment accountFragment on Account {
    id
    uuid
    name
    corpNumber
    webSite
    telephoneNumber
    employeeNumber
    address
    zipCode
    mailAddress
    representativePerson
    capitalFund
    establishedYearMonth
    domain
    faxNumber
    shortName
    subdomain
    tel
    accountClosingMonth
    officesDomesticBranchesNumber
    officesDomesticFactoriesNumber
    officesDomesticStoresNumber
    officesForeignBranchesNumber
    officesForeignFactoriesNumber
    officesForeignStoresNumber
    accountClosingMonth
    companyId
    listingMarket
    abstract
    customizeItemPayload
  }
`;
