import gql from 'graphql-tag';

export default gql`
  fragment accountPoolFragment on AccountPool {
    id
    uuid
    salesforceAccountId
    salesforceLeadId
  }
`;
