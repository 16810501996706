import React from 'react';
import styled from 'styled-components';
import {Filter as FilterIcon} from 'components/Ui/Icon';
import {Popover} from 'components/antd';
import {useParams} from 'react-router-dom';
import {
  useProjectQuery,
  useClientPreleadCustomizeItemsQuery,
  useCurrentUserQuery,
} from 'api';
import useSearchParams from '../useSearchParams';
import Filter from './Filter';
import {useCurrentClientQuery} from 'api';
import {useRecoilState} from 'recoil';
import filterOptionsAtom from './Filter/filterOptionsAtom';
import _ from 'lodash';

const defaultFilterOptions = [
  {text: 'No.', value: 'serialNumber'},
  {text: '企業担当者', value: 'assigneeIds'},
  {text: 'リマインド', value: 'remind'},
  {text: 'タグ', value: 'tagIds'},
  {text: '重複', value: 'isDuplicate'},
  {text: '複数名寄せ候補', value: 'isMultiple'},
  {text: 'キーマン情報', value: 'hasKeyPerson'},
  {text: '配信停止', value: 'isUnsubscribed'},
  {text: 'コメント回数', value: 'commentCounts'},
  {text: 'プレリードステータス', value: 'preleadStatusIds'},
  {text: 'フォーム送信ステータス', value: 'mailStatuses'},
  {text: 'フォーム送信数', value: 'mailCounts'},
  {text: 'フォーム送信期間', value: 'mailTerm'},
  {text: 'TELステータス', value: 'telStatusIds'},
  {text: '最新TELステータス', value: 'latestTelStatusIds'},
  {text: '最新TEL更新ユーザー', value: 'latestTelUserIds'},
  {text: 'TEL件数', value: 'telCounts'},
  {text: 'TEL更新日', value: 'telTerm'},
  {text: '最新TEL更新日', value: 'latestTelTerm'},
  {text: 'メールキャンペーン名', value: 'mailCampaignIds'},
  {text: 'メールキャンペーン開封', value: 'mailCampaignOpenCounts'},
  {text: 'メールキャンペーンクリック', value: 'mailCampaignClickCounts'},
  {text: 'メールキャンペーン回数', value: 'mailCampaignSendsCounts'},
  {text: 'フォーム送信クリック回数', value: 'clickCounts'},
  {text: 'フォーム送信クリック日時', value: 'clickTerm'},
  {text: 'フォーム送信クリックURL', value: 'clickedCampaignUrlIds'},
  {text: 'フォーム送信クリックURL', value: 'clickedMailFormVersionUrlIds'},
  {text: 'フォームメールステータス', value: 'responseStatuses'},
  {text: 'クリックキャンペーン', value: 'clickedCampaignIds'},
  {text: 'プレスリリース公開', value: 'pressReleaseSince'},
  {text: '求人情報', value: 'jobListingsSince'},
  {text: '都道府県', value: 'prefectureIds'},
  {text: '従業員数', value: 'employeeNumber'},
  {text: '上場区分', value: 'listingMarkets'},
  {text: '資本金', value: 'capitalFund'},
  {text: '事業所数', value: 'officesNumber'},
  {text: '決算月', value: 'accountClosingMonths'},
  {text: '設立年月', value: 'establishedDate'},
  {text: '業種', value: 'categoryIds'},
];

const FilterPopover = () => {
  const {projectId} = useParams<{projectId: string}>();
  const {query, setQuery} = useSearchParams();
  const [filterOptions] = useRecoilState(filterOptionsAtom);
  const [options, setOptions] = React.useState(filterOptions);
  const [filters, setFilters] = React.useState([null]);

  const {data: {project = null} = {}} = useProjectQuery({
    variables: {uuid: projectId},
    skip: !projectId,
  });

  React.useEffect(() => {
    setOptions(
      filterOptions.filter(
        (option) =>
          option.value !==
          (project?.contentMailFormVersionId
            ? 'clickedCampaignUrlIds'
            : 'clickedMailFormVersionUrlIds'),
      ),
    );
  }, [filterOptions, project]);

  React.useEffect(() => {
    const filterOptionValues = options.map((option) => option.value);
    const currentFilters = Object.entries(query)
      .filter((entry) =>
        Array.isArray(entry[1])
          ? entry[1].length
          : entry[1] && filterOptionValues.includes(entry[0]),
      )
      .map((entry) => entry[0]);

    if (currentFilters.length > 0) {
      setFilters(currentFilters);
    }
  }, [options, query]);

  return (
    <PopoverContent>
      <h3>フィルタ</h3>
      {filters.map((filter, index) => (
        <Filter
          key={index}
          filter={filter}
          availableOptions={options.filter(
            (option) =>
              option.value === filter || !filters.includes(option.value),
          )}
          onChange={(value) => {
            const newFilters = [...filters];

            if (filter) {
              setQuery({[filters[index]]: null});
            }

            if (value) {
              newFilters[index] = value;
            } else {
              newFilters.splice(index, 1);
            }
            setFilters(newFilters);
          }}
        />
      ))}
      <div>
        <a
          onClick={() => {
            setFilters([...filters, null]);
          }}>
          フィルタ項目を追加
        </a>
      </div>
    </PopoverContent>
  );
};

export default () => {
  const {data: {currentUser = {}} = {}} = useCurrentUserQuery({});
  const {data: {currentClient: {currentLeadContract} = {}} = {}} =
    useCurrentClientQuery();
  const {query} = useSearchParams();

  const {data: {currentClient: {customizeItems = []} = {}} = {}} =
    useCurrentClientQuery({});

  const {data: {clientPreleadCustomizeItems = []} = {}} =
    useClientPreleadCustomizeItemsQuery();

  const [filterOptions, setFilterOptions] = useRecoilState(filterOptionsAtom);
  const canListKeyPerson =
    currentUser?.role === 'admin' || currentLeadContract?.keyPersonDisplayable;

  const optionsFilterd = React.useMemo(() => {
    return canListKeyPerson
      ? [...defaultFilterOptions]
      : defaultFilterOptions.filter(
          (option) => option.value !== 'hasKeyPerson',
        );
  }, [canListKeyPerson]);

  React.useEffect(() => {
    const options = [...optionsFilterd];
    customizeItems.forEach((customizeItem) =>
      options.push({
        text: customizeItem.name,
        value: _.camelCase(customizeItem.columnName),
      }),
    );

    clientPreleadCustomizeItems.forEach((customizeItem) =>
      options.push({
        text: customizeItem.name,
        value: `c.${customizeItem.id}`,
      }),
    );

    setFilterOptions(options);
  }, [customizeItems, clientPreleadCustomizeItems, optionsFilterd]);

  const active = React.useMemo(() => {
    const currentFilters = Object.entries(query)
      .filter((entry) =>
        Array.isArray(entry[1]) ? entry[1].length : entry[1] || entry[1] === 0,
      )
      .map((entry) => entry[0]);

    return filterOptions
      .map((option) => option.value)
      .some((value) => currentFilters.includes(value));
  }, [query, filterOptions]);

  return (
    <PopoverButton
      content={FilterPopover}
      placement="bottom"
      trigger="click"
      className={active ? 'active' : ''}>
      <FilterIcon color={active ? '#68b5fb' : '#BDC4CB'} />
      <span>フィルタ</span>
    </PopoverButton>
  );
};

const PopoverButton = styled(Popover)`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    height: 17px;
    width: 27px;
    margin-right: 5px;
  }

  &.active {
    span {
      color: #68b5fb;
    }
  }
`;

const PopoverContent = styled.div`
  background: white;
  width: 640px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  font-size: 12p;

  h3 {
    font-weight: bold;
    color: #bdc4cb;
    margin-bottom: 0.5rem;
  }
`;
