import React from 'react';
import {Text, Select, Date, Number} from '../Icon';
import {ClientPreleadCustomizeItem} from 'api';
import DateInput from './Date';
import NumberInput from './Number';
import TextInput from './Text';

export default ({item}: {item: ClientPreleadCustomizeItem}) => {
  const ItemIcon = (type: string) => {
    if (type === 'text') return <Text />;
    if (type === 'date') return <Date />;
    if (type === 'select') return <Select />;
    if (type === 'number') return <Number />;
  };

  const ItemInput = (item: ClientPreleadCustomizeItem) => {
    const type = item?.dataType;
    if (type === 'date') return <DateInput item={item} />;
    if (type === 'number') return <NumberInput item={item} />;
    if (type === 'text') return <TextInput item={item} />;
    return <></>;
  };

  return (
    <div key={item.id} className="flex gap-5 items-center">
      <div className="flex gap-1 items-center h-7 w-32">
        <div className="flex items-center">{ItemIcon(item?.dataType)}</div>
        <span className="font-bold text-sm">{item?.name}</span>
      </div>
      <div>{ItemInput(item)}</div>
    </div>
  );
};
