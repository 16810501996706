import React from 'react';
import StatusSelect from './StatusSelect';
import UserSelect from './UserSelect';
import Category from './Category';
import Score from './Score';
import TagSelect from './TagSelect';
import {useCurrentUserQuery} from 'api';
import SalesforceAccountUser from './SalesforceAccountUser';

export default () => {
  const {data: {currentUser = null} = {}} = useCurrentUserQuery({});
  return (
    <div className="w-96">
      <div className="bg-white border border-c-border rounded">
        <h2 className="h-10 flex items-center border-b border-c-border text-sm px-4 font-bold text-c-light">
          基本情報
        </h2>
        <div className="p-4">
          <div className="flex flex-col text-sm gap-2">
            {currentUser?.role === 'admin' && (
              <>
                <StatusSelect />
                <UserSelect />
                <SalesforceAccountUser />
                <TagSelect />
                <div className="border-b border-c-border p-2" />
                <Score />
              </>
            )}
            <Category />
          </div>
        </div>
      </div>
    </div>
  );
};
