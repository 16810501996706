import React from 'react';
import {SalesforceUserSelector} from 'components/Prospect/SalesforceUserSelector';
import {SalesforceAccount, useUpdateSalesforceAccountOwnerMutation} from 'api';

interface Props {
  salesforceAccount: SalesforceAccount;
}

export default ({salesforceAccount}: Props) => {
  const [erorrMessage, setErrorMessage] = React.useState('');
  const [updateOwner] = useUpdateSalesforceAccountOwnerMutation({
    onCompleted: (data) => {
      setErrorMessage(data.updateSalesforceAccountOwner.error);
    },
  });
  return (
    <div className="flex gap-2 items-center">
      <div className="text-c-light text-xm mr-2">所有者</div>
      <div>
        <SalesforceUserSelector
          salesforceUser={salesforceAccount?.salesforceOwner}
          onChange={(value) => {
            updateOwner({
              variables: {
                id: salesforceAccount?.id,
                salesforceUserIdString: value,
              },
            });
          }}
        />
      </div>
      {erorrMessage && <p className="text-red-500">{erorrMessage}</p>}
    </div>
  );
};
