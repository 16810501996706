import React from 'react';

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-2 items-start">
          <div>
            <h2 className="text-base m-0 font-bold">失注時期</h2>
            <p className="m-0 text-sm">
              重要度を高める失注時期を選択してください
            </p>
          </div>
          <div className="flex items-center gap-2">
            <select className="form-select border-c-border rounded text-sm h-8 pt-[3px] w-32">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            <span>ヶ月</span>
            <span>〜</span>
            <select className="form-select border-c-border rounded text-sm h-8 pt-[3px] w-32">
              <option>1</option>
              <option>2</option>
              <option>3</option>
              <option>4</option>
            </select>
            <span>ヶ月</span>
          </div>
        </div>
      </div>
    </div>
  );
};
