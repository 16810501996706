import React from 'react';
import usePreleadContext from '../usePreleadContext';
import {useKeyPersonsByPreleadQuery, usePreleadQuery} from 'api';
import Name from './Name';
import Sns from './Sns';
import Department from './Department';
import Position from './Position';
import Media from './Media';

export default () => {
  const {preleadId} = usePreleadContext();

  if (!preleadId) return null;
  const {data: {prelead} = {}} = usePreleadQuery({
    variables: {uuid: preleadId},
    skip: !preleadId,
  });
  const {data: {keyPersonsByPrelead = []} = {}} = useKeyPersonsByPreleadQuery({
    variables: {
      uuid: prelead.uuid,
    },
    skip: !prelead,
  });

  return (
    <div>
      <h2 className="mb-4 font-bold text-[12px] text-c-base">キーマン情報</h2>
      {keyPersonsByPrelead.map((keyPerson) => (
        <div key={keyPerson.id} className="border-b border-c-border p-4">
          <div className="flex">
            <Name keyPerson={keyPerson} />
            <Sns keyPerson={keyPerson} />
          </div>
          <Department keyPerson={keyPerson} />
          <Position keyPerson={keyPerson} />
          <Media keyPerson={keyPerson} />
        </div>
      ))}
    </div>
  );
};
