import {KeyPerson} from 'api';
import React from 'react';
interface Props {
  keyPerson: KeyPerson;
}

export default ({keyPerson}: Props) => {
  if (!keyPerson.article) return null;

  return (
    <div className="pb-2">
      <div className="px-2 bg-c-bg text-[#666666]">掲載メディア</div>
      <div className="flex justify-between">
        <a
          href={`${keyPerson?.article?.url}`}
          className="truncate underline"
          target="_blank">
          {keyPerson?.article?.caseStudy?.siteName}
        </a>
        <div>{keyPerson?.article?.publishedDate}</div>
      </div>
    </div>
  );
};
