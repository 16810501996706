import React from 'react';
import Setting from './Setting';
import Analytics from './Analytics'

export default () => {
  return (
    <div className="bg-white p-4 flex flex-col gap-6">
      <Setting />
      { /* <Analytics/> */}
    </div>
  );
};
